import React from "react"
import styled from "styled-components"

import SEO from "./../components/seo"
import Profile from "./../components/profile"
import Footer from "../components/Footer"
import { Link } from "gatsby"

const FAQ = () => {
  return (
    <>
      <SEO
        slug="/faq/"
        title="FAQ | MTG Builders, a Deck Builder app"
        description="Is MTG Builders just an MTG Deck Builder? Here, you'll find the answers to all your questions."
      />
      <Profile />
      <PageLayout>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "5rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          MTG Builders
        </MyText>
        <MyText
          as={"h1"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          a MTG Deck Builder web app
        </MyText>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          to build the future of Magic
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "2.8rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          You made this?
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          <a
            href="https://www.michelepaladino.dev/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ "text-decoration": "underline #B566DB double" }}
          >
            I made this.
          </a>
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Why?
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          As a Full Stack Developer, creating MTG Builders has been a great way
          to further improve my skills in technologies like React, CSS, FaunaDB,
          and many others.
          <br />
          This doesn't mean that a love of the game hasn't been a factor in my
          choice.
          <br />
          I'm among those that spent the summers playing Magic under the shade
          of the trees, while others were chasing first loves fueled by the hot
          sun.
          <br />
          Worth it!
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Can I trust you?
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          As I've already stated in the{" "}
          <Link to="/privacy/">Privacy Policy</Link>, I delegate a lot of the
          hard work to other services. <br />
          For example, payments are managed by PayPal, and auth by FaunaDB.
          <br />
          All I had to do was reading their docs carefully and following best
          practices.
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Why isn't this free?
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          I have to pay for a lot of the cool services this web app depends on.
          <br />I intend to constantly improve MTG Builders.
          <br />
          If you feel like this project deserves support, and if you can, please
          consider subscribing!
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Will this ever go on sale?
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          The price you see today is the best price you could ever get.
          <br />
          In marketing, the most crucial tactic is to instill a sense of urgency
          in the customer, the so-called FOMO (fear of missing out).
          <br />
          "Hurry-up, get this 90% off! Limited offer!" - Looks stupid, but it
          works. <br /> I know it has worked on me many, many times, but then
          I've always felt a bad taste in my mouth.
          <br />I want you to feel that you matter and that I understand you
          because I am also you. <br />
          Subscribe, join the Discord, and let's build the best future for Magic
          and for this web app!
        </MyText>
        <MyText
          as={"h1"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          A modern MTG Deck Builder needs updates.
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Will you continue working on this web app?
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Yes! Go to <Link to="/roadmap/">Roadmap</Link> to find out what I'm
          currently working on.
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          When I try to sub, it says Paypal Error.
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Make sure you are not blocking necessary-cookies in any way. Necessary
          cookies are "the good ones", those essential for browsing the website
          and using its features, such as accessing secure areas of the site.
          <br />
          You can also try to log-out and log-in again.
          <br />
          Keep your browser up to date.
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          I have an idea for an app that will make us billionaires!
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Of course it will! Reach out to me and let's make it happen.
        </MyText>
        <Footer />
      </PageLayout>
    </>
  )
}

export default FAQ

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 2rem;
  min-height: 100vh;
  width: 100%;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  /*   justify-content: center; */
  align-items: center;
`

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  text-align: center;
  max-width: 1200px;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`
